import React from 'react';
import PageAnalytics from './page-analytics';
import SubPageContent from './sub-page-content-type';

const PackageSubPage = (props: any) => {
	const parentPackageName = props.data.workspaceInfo.title;
	const pageName = props.pageContext.slug;
	const tabNames: string[] = props.hasTabs
		? props.data.mdx.nodes.map((node: any) => node.parent.name)
		: [];

	return (
		<PageAnalytics
			tabNames={tabNames}
			packageName={props.pageContext.name}
			pageTitle={`${parentPackageName}-${pageName}`}
		>
			<SubPageContent {...props} />
		</PageAnalytics>
	);
};

export default PackageSubPage;
