import React from 'react';

import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';

import { HeadingContextProvider, type StatusPanelProps } from '@af/design-system-docs-ui';

import PageWithLocalNav from '../../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/templates/page-with-local-nav';
import titleify from '../../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/utilities/titleify';

import SubPageContent from './subpage-content';
import getHeroSectionText from './utils/getHeroSectionText';
import { getCurrentTab } from './utils/use-tabs';

const getPageInfo = (hasTabs: boolean, data: any, currentTab: any) => {
	return hasTabs
		? getHeroSectionText(currentTab.mdx, data.workspaceInfo)
		: getHeroSectionText(data.page, data.workspaceInfo);
};

export default ({
	data,
	pageContext,
}: {
	data: any;
	pageContext: {
		hasTabs: boolean;
		hideChangelog: boolean;
		status: StatusPanelProps;
	};
}) => {
	const { hasTabs } = pageContext;
	const { pathname } = useLocation();
	let currentTab;

	if (hasTabs) {
		currentTab = getCurrentTab(pathname, data.mdx.nodes);
	}

	const { title, description } = getPageInfo(hasTabs, data, currentTab);

	const subpageTabName = currentTab ? titleify(currentTab.name) : 'index';

	return (
		<HeadingContextProvider page={currentTab?.name}>
			<PageWithLocalNav
				title={title}
				description={description}
				status={pageContext.status}
				subpageTabName={subpageTabName}
			>
				<SubPageContent hasTabs={hasTabs} data={data} pageContext={pageContext} />
			</PageWithLocalNav>
		</HeadingContextProvider>
	);
};

export const query = graphql`
	query ($name: String!, $mdxPath: String, $hasTabs: Boolean!) {
		workspaceInfo(name: { eq: $name }) {
			title
			description
			slug
		}
		changelog(packageName: { eq: $name }) {
			mdx: childMdx {
				id
				body
				headings {
					value
					depth
				}
			}
		}
		page: mdx(fileAbsolutePath: { eq: $mdxPath }) @skip(if: $hasTabs) {
			body
			id
			exportPropTypes
			frontmatter {
				title
				description
			}
			headings {
				depth
				value
			}
		}
		mdx: allMdx(
			sort: { fields: frontmatter___order, order: ASC }
			filter: { fileAbsolutePath: { glob: $mdxPath } }
		) @include(if: $hasTabs) {
			nodes {
				frontmatter {
					title
					description
				}
				exportPropTypes
				headings {
					depth
					value
				}
				parent {
					... on File {
						name
					}
				}
				body
			}
		}
	}
`;
