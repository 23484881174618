import React from 'react';
import ContentRenderer from '../../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/content/content-renderer';
import TabbedSection from './tabbed-section';

interface SubPageContentProps {
	hasTabs: boolean;
	data: any;
	pageContext: any;
}

/** TODO this should be consolidated with `content-type.tsx` */
const SubPageContent = ({ data, pageContext, hasTabs }: SubPageContentProps) => {
	return hasTabs ? (
		<TabbedSection data={data} pageContext={pageContext} />
	) : (
		<ContentRenderer id={data.page.id} body={data.page.body} />
	);
};

export default SubPageContent;
